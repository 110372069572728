import PropTypes from 'prop-types';
import Link from 'redux-first-router-link';
import styled, { css } from 'styled-components';

import dkFlag from 'components/FlagBadge/images/danish-flag.png';
import RoundLink from 'components/RoundLink';
import { RelativeTime } from 'components/Time';
import { betTypeColors } from 'utils/css-mixins';
import media from 'utils/media';

import { sharedButtonStyles } from './styles';

const StyledLink = RoundLink(styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;

  & + & {
    margin-left: 5px;
  }

  ${media.desktop`
    align-items: initial;
     & + & {
      margin-left: 0;
      margin-top: 10px;
    }
  `}
`);

const StyledInner = styled.div`
  ${sharedButtonStyles}
  background: ${props =>
    props.dkBranded ? undefined : betTypeColors(props.betType).to};
  background: ${props =>
    props.dkBranded
      ? undefined
      : css`linear-gradient(
    0deg,
    ${props => betTypeColors(props.betType).from} 0px,
    ${props => betTypeColors(props.betType).to}
  )`};

  ${media.desktop`
    background: ${props =>
      props.dkBranded ? '#5CA8DB' : betTypeColors(props.betType).to};
  `}
`;

const StyledBetType = styled.span`
  font-size: 14px;
  line-height: 0;
  font-weight: 700;
`;

const StyledTrack = styled.span`
  display: none;

  ${media.desktop`
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    margin-left: 8px;
  `}
`;

const StartTimeMobile = styled(RelativeTime)`
  font-size: 11px;
  font-weight: 600;
  letter-spacing: -0.2px;
  line-height: 1.4;
  color: #4a4a4a;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  margin-top: 5px;

  ${media.desktop`
    display: none;
  `}
`;

const StartTimeDesktop = styled(RelativeTime)`
  display: none;
  font-size: 13px;
  color: inherit;
  opacity: 0.9;

  ${media.desktop`
    display: inline-block;
    margin-left: auto;
  `}
`;

const StyledDkFlag = styled.span`
  display: inline-block;
  width: 100%;
  height: 100%;
  background: url(${dkFlag}) no-repeat left center;
  background-size: 100% auto;
  border-radius: 3px;

  ${media.desktop`
    width: 24px;
    height: 16px;
    border-radius: 0;
    box-shadow: 0 2px 4px 0 rgba(136,136,136,0.5);
  `}
`;

const RoundButton = ({
  round: {
    slug,
    country,
    bet_type: betType,
    start_time: startTime,
    track_name: track,
  },
}) => {
  const dkBranded = country === 'DK' && ['V4', 'V5'].includes(betType);
  const date = new Date(startTime);
  return (
    <StyledLink
      to={{
        type: 'ROUND_TIP',
        payload: { slug },
      }}
    >
      <StyledInner betType={betType} dkBranded={dkBranded}>
        {dkBranded ? (
          <StyledDkFlag />
        ) : (
          <StyledBetType>{betType}</StyledBetType>
        )}
        <StyledTrack>
          {dkBranded ? `${betType} ` : ''}
          {track}
        </StyledTrack>
        <StartTimeDesktop date={date} compact />
      </StyledInner>
      <StartTimeMobile date={date} compact timePrefix={false} />
    </StyledLink>
  );
};

RoundButton.propTypes = {
  round: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    country: PropTypes.string,
    bet_type: PropTypes.string.isRequired,
    start_time: PropTypes.string.isRequired,
    track_name: PropTypes.string.isRequired,
  }),
};

export default RoundButton;
