import './utils/polyfills';

import theme from '@trmediaab/theme-travrondenspel';
import { ThemeProvider } from '@trmediaab/zebra';
import {
  registerLocale as registerDatePickerLocale,
  setDefaultLocale as setDefaultDatePickerLocale,
} from 'react-datepicker';
import { DFPSlotsProvider } from 'react-dfp';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';

import ErrorBoundary from 'components/ErrorBoundary';
import svLocale from 'utils/svDateLocale';

import Page from './components/Page';
import GlobalCSS from './global-styles';
import store from './store';

registerDatePickerLocale('sv', svLocale);
setDefaultDatePickerLocale('sv');

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <GlobalCSS />
    <ErrorBoundary coverFullScreen>
      <Provider store={store}>
        <DFPSlotsProvider dfpNetworkId="122244050" lazyLoad>
          <HelmetProvider>
            <Page />
          </HelmetProvider>
        </DFPSlotsProvider>
      </Provider>
    </ErrorBoundary>
  </ThemeProvider>,
  document.querySelector('#root'),
);
