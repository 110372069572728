import { Check } from '@styled-icons/material';
import { Component } from 'react';
import styled from 'styled-components';

import { colors } from '../../../utils/css-mixins';

const Wrapper = styled.div`
  margin-top: 16px;
`;

const Item = styled.div`
  display: flex;

  & + & {
    margin-top: 6px;
  }
`;

const CheckWrapper = styled.span`
  flex: 0 0 32px;
`;

const StyledCheck = styled(Check)`
  color: ${colors.orange};
  position: relative;
  top: -1px;
`;

class CheckList extends Component {
  render() {
    const { Data } = this.props;
    return (
      <Wrapper>
        {Data.map(item => (
          <Item key={item}>
            <CheckWrapper>
              <StyledCheck size="25" />
            </CheckWrapper>
            {item}
          </Item>
        ))}
      </Wrapper>
    );
  }
}
export default CheckList;
