import { css } from 'styled-components';

import { spacing } from 'utils/css-mixins';
import media from 'utils/media';

export const sharedButtonStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 40px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(175, 175, 175, 0.5);

  ${media.desktop`
    width: auto;
    flex-direction: row;
    justify-content: initial;
    padding: 0 ${spacing.siteGutter}px;
  `}
`;
