import PropTypes from 'prop-types';

import { formatDate, formatDateRelative } from 'utils/format';

/**
 * Displays a date both in human and machine readable form.
 *
 * Formatting docs: https://date-fns.org/docs/format
 */
const Time = ({ date, format, className, icon }) => (
  <time className={className} dateTime={date.toISOString()}>
    {icon}
    {formatDate(date, format)}
  </time>
);

Time.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  format: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.element,
};

Time.defaultProps = {
  format: undefined,
};

/**
 * Displays a date both in human and machine readable form.
 * The date is displayed as words relative to now.
 *
 * Docs: https://date-fns.orgformatRelative
 *
 * @param  compact  Use less words. See utils/svDateLocale.js
 * @param  timePrefix  Set to false to remove time prefix ('kl.')
 */
const RelativeTime = ({ date, compact, timePrefix, className, icon }) => (
  <time className={className} dateTime={date.toISOString()}>
    {icon}
    {formatDateRelative(date, { compact, timePrefix })}
  </time>
);

RelativeTime.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  compact: PropTypes.bool,
  timePrefix: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.element,
};

RelativeTime.defaultProps = {
  compact: false,
  timePrefix: true,
};

export default Time;
export { RelativeTime };
