import PropTypes from 'prop-types';
import styled from 'styled-components';

import Db from './db.svg';
import TBlue from './t-blue.svg';
import TRed from './t-red.svg';
import TWhite from './t-white.svg';
import LogoGrayData from './travronden-gray.svg';
import LogoRedData from './travronden-red.svg';

const srcMap = {
  travronden: LogoRedData,
  travronden2: LogoGrayData,
  't-blue': TBlue,
  't-red': TRed,
  't-white': TWhite,
  db: Db, // Daniel Berglund signature
};

function SvgElement(props) {
  return <img src={props.src} alt={props.alt} {...props} />;
}

SvgElement.propTypes = {
  src: PropTypes.PropTypes.string.isRequired,
};

const SvgContainer = styled.div`
  line-height: 0;
  /* Optically correct center */
  padding-right: ${props => (props.isDb ? '4px' : undefined)};
`;

const Svg = ({ name, ...props }) => (
  <SvgContainer isDb={name === 'db'}>
    <SvgElement {...props} src={srcMap[name]} />
  </SvgContainer>
);

Svg.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Svg;
