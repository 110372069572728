import { css } from 'styled-components';

export const breakpoints = {
  giant: 1200,
  desktop: 1020,
  tablet: 768,
  phone: 576,
  xs: 575, // Use with the max function to target anything below `phone`
};

function getSizeFromBreakpoint(breakpoint) {
  if (breakpoints[breakpoint]) {
    return `${breakpoints[breakpoint]}px`;
  } else if (Number.parseInt(breakpoint, 10)) {
    return breakpoint === Number.parseInt(breakpoint, 10)
      ? `${breakpoint}px`
      : breakpoint;
  } else {
    console.error(`Invalid breakpoint '${breakpoint}'`);
    return '0';
  }
}

export const min =
  breakpoint =>
  (...args) =>
    css`
      @media (min-width: ${getSizeFromBreakpoint(breakpoint)}) {
        ${css(...args)}
      }
    `;

export const max =
  breakpoint =>
  (...args) =>
    css`
      @media (max-width: ${getSizeFromBreakpoint(breakpoint)}) {
        ${css(...args)}
      }
    `;

export const between =
  (minBreakpoint, maxBreakpoint) =>
  (...args) =>
    css`
      @media (min-width: ${getSizeFromBreakpoint(
          minBreakpoint,
          breakpoints,
        )}) and (max-width: ${getSizeFromBreakpoint(maxBreakpoint)}) {
        ${css(...args)}
      }
    `;

const shorthand = Object.keys(breakpoints).reduce((acc, name) => {
  const size = `${breakpoints[name]}px`;
  acc[name] = (...args) => css`
    @media (min-width: ${size}) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

const media = {
  ...shorthand,
  min,
  max,
  between,
};

export default media;
