import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import Spinner from 'components/Spinner';

import { selectError, selectLoading, selectRound } from './selectors';

const mapStateToProps = (state, props) => {
  const selectors = {
    loading: selectLoading(state, props),
    error: selectError(state, props),
    round: selectRound(state, props),
  };

  return selectors;
};

export default connect(mapStateToProps)(
  class Round extends PureComponent {
    static propTypes = {
      render: PropTypes.func.isRequired,
      id: PropTypes.string,
    };

    static defaultProps = {
      round: undefined,
    };

    render() {
      const { round, render } = this.props;
      return <Spinner {...this.props}>{round && render(round)}</Spinner>;
    }
  },
);
