import PropTypes from 'prop-types';
import styled from 'styled-components';

import media from 'utils/media';

const Root = styled.header`
  display: none;

  ${media.desktop`
    display: block;
    margin-bottom: 18px;
  `}
`;

const Heading = styled.h2`
  font-size: 18px;
  line-height: 1.5;
  font-weight: 600;
  margin: 0 0 8px;
`;

const Lead = styled.p`
  font-size: 13px;
  line-height: 1.615384615;
`;

const Header = ({ heading, lead }) => (
  <Root>
    <Heading>{heading}</Heading>
    <Lead>{lead}</Lead>
  </Root>
);

Header.propTypes = {
  heading: PropTypes.string.isRequired,
  lead: PropTypes.string.isRequired,
};

export default Header;
