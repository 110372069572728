import { WifiTethering } from '@styled-icons/material';
import { rgba } from 'polished';
import styled, { keyframes } from 'styled-components';

import { colors } from 'utils/css-mixins';

const defaultIconSize = 24;

const IconLiveWrapper = styled.span`
  display: inline-block;
  position: relative;
  line-height: 0;

  svg {
    fill: ${props => props.fillColor};
  }
`;

const animationRule = color =>
  keyframes`
    0% {
      box-shadow: 0 0 0 0 ${rgba(color, 0.4)};
    }

    70% {
      box-shadow: 0 0 0 10px ${rgba(color, 0)};
    }

    100% {
      box-shadow: 0 0 0 0 ${rgba(color, 0)};
    }
`;

const IconLiveTransmit = styled.span`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
  animation: ${props =>
      !props.disabled ? animationRule(props.fillColor) : 'none'}
    2s infinite;
`;

export const IconLive = props => {
  const { white, size: iconSize = defaultIconSize, disabled } = props;
  const fillColor = white ? colors.white : colors.orange;
  return (
    <IconLiveWrapper fillColor={fillColor}>
      <WifiTethering size={iconSize} />
      <IconLiveTransmit
        disabled={disabled}
        fillColor={fillColor}
        size={iconSize}
      />
    </IconLiveWrapper>
  );
};
