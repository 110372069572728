import config from 'config';

export const getLoginUrl = () =>
  `${config.AUTH_URL}?ReturnUrl=${encodeURI(window.location.href)}`;

export const getLogoutUrl = () =>
  `${config.AUTH_URL}/logout?ReturnUrl=${encodeURI(window.location.href)}`;

export const getRegisterUrl = () =>
  `${config.AUTH_URL}/register?ReturnUrl=${encodeURI(window.location.href)}`;
