import styled from 'styled-components';

import { colors, spacing } from 'utils/css-mixins';
import media from 'utils/media';

export const Input = styled.input`
  height: 40px;
  width: 100%;
  min-width: 0;
  border: 1px solid ${colors.border};
  padding: 6px ${spacing.siteGutter}px;
  border-radius: 2px;
  background-color: ${colors.white};

  ${media.tablet`
    height: 46px;
  `}

  &:focus {
    ${media.max('xs')`
      font-size: 16px;
    `}
  }
`;

export const Textarea = styled.textarea`
  border: 1px solid ${colors.border};
  padding: 6px ${spacing.siteGutter}px;
  width: 100%;
  border-radius: 2px;
  background-color: ${colors.white};
`;
