import styled from 'styled-components';

import { colors } from 'utils/css-mixins';
import media from 'utils/media';

import Header from './Header';
import { sharedButtonStyles } from './styles';

const Root = styled.section`
  display: none;

  ${media.desktop`
    display: block;
  `}
`;

const LinkButton = styled.a`
  ${sharedButtonStyles}
  background-color: ${colors.buttonprimary};
  font-size: 14px;
  line-height: 0;
  font-weight: 700;
  color: #fff;

  ${media.desktop`
     & + & {
      margin-top: 10px;
    }
  `}
`;

// TODO 1 - copy says köpandel, link goes to atg
// TODO 2 - both links resolve to same page
const KopAndelPromo = () => (
  <Root>
    <Header
      heading="Köpandel"
      lead="På köpandel.se hittar du andelsspel från Sveriges bästa tipsters."
    />
    <LinkButton
      href="https://www.atg.se/hedlunds/spel/66641"
      rel="noopener noreferrer"
      target="_blank"
    >
      Travrondens andelslag - V75
    </LinkButton>
    <LinkButton
      href="https://www.atg.se/hedlunds/spel/73911"
      rel="noopener noreferrer"
      target="_blank"
    >
      Travrondens andelslag - V86
    </LinkButton>
  </Root>
);

export default KopAndelPromo;
