import styled from 'styled-components';

import { colors, fonts, spacing } from 'utils/css-mixins';
import media from 'utils/media';

const StyledText = styled.div`
  font-size: ${({ size: fontSize }) => fontSize || 16}px;
  line-height: ${({ lineHeight }) => lineHeight || '1.625'};
`;

export const Text = props => (
  <StyledText
    {...props}
    dangerouslySetInnerHTML={
      props.html == null ? undefined : { __html: props.html }
    }
  />
);

export const PageTitle = styled.h1`
  font-family: ${fonts.secondary};
  margin-bottom: ${spacing.siteGutter}px;
  font-size: 28px;
  line-height: 1.142857143;

  ${media.tablet`
    font-size: 38px;
    letter-spacing: -0.0125em;
    line-height: 1.105263158;
  `}

  ${media.giant`
    font-size: 50px;
    line-height: 1.04;
  `}
`;

export const Preamble = styled.p`
  font-family: ${fonts.secondary};
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 1.3;

  ${media.tablet`
    font-size: 22px;
    letter-spacing: -0.0125em;
    line-height: 1.272727273;
  `}

  ${media.desktop`
    font-size: 24px;
    line-height: 1.333333333;
  `}
`;

export const SupTitle = styled.div`
  margin-bottom: ${spacing.siteGutter * 0.75}px;
  color: ${colors.bluelight};
  font-weight: 600;
  text-transform: uppercase;

  ${media.tablet`
    font-size: 18px;
    line-height: 1.444444444;
  `}
`;

export const NoWrap = styled.span`
  white-space: nowrap;
`;
