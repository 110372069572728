import styled from 'styled-components';

export const VerticalList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 5px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const HorizontalList = styled(VerticalList)`
  li {
    display: inline;
  }
`;
