const sizes = [
  [320, 50],
  [320, 160],
  [320, 320],
  [728, 90],
  [728, 180],
  [980, 120],
  [980, 240],
];

const inFeedSizeMappings = [
  {
    viewport: [0, 0],
    sizes: [
      [320, 50],
      [320, 160],
      [320, 320],
    ],
  },
  {
    viewport: [758, 0],
    sizes: [
      [728, 90],
      [728, 180],
    ],
  },
  {
    viewport: [1020, 0],
    sizes: [
      [320, 50],
      [320, 160],
      [320, 320],
    ],
  },
  {
    viewport: [1130, 0],
    sizes: [
      [728, 90],
      [728, 180],
    ],
  },
  {
    viewport: [1410, 0],
    sizes: [
      [980, 120],
      [980, 240],
    ],
  },
];

const panoramaSizeMapping = [
  {
    viewport: [0, 0],
    sizes: [
      [320, 50],
      [320, 160],
      [320, 320],
    ],
  },
  {
    viewport: [758, 0],
    sizes: [
      [728, 90],
      [728, 180],
    ],
  },
  {
    viewport: [1010, 0],
    sizes: [
      [980, 120],
      [980, 240],
    ],
  },
];

export const AD_UNITS = {
  panorama: {
    name: 'RONDEN_SPEL_Panorama_1',
    sizes,
    sizeMapping: panoramaSizeMapping,
  },
  feed1: {
    name: 'RONDEN_SPEL_Panorama_1',
    sizes,
    sizeMapping: inFeedSizeMappings,
  },
  feed2: {
    name: 'RONDEN_SPEL_Panorama_2',
    sizes,
    sizeMapping: inFeedSizeMappings,
  },
  feed3: {
    name: 'RONDEN_SPEL_Panorama_3',
    sizes,
    sizeMapping: inFeedSizeMappings,
  },
};
