import getUnixTime from 'date-fns/getUnixTime';
import { createSelector } from 'reselect';

import { createGenericSelector } from 'containers/GenericFetch/selectors';

import { NEWS_NAMESPACE } from './constants';

const feedSelector = state => state.feed;
const getNewsKey = () => `latest`;

// Current head of visible results list
const getFeedCursor = createSelector(feedSelector, state => state.cursor);

const getFeatured = createSelector(feedSelector, state => state.featured);

export const getFeedList = createSelector(
  feedSelector,
  state => state.responseList,
);

export const getFeedFrom = createSelector(feedSelector, state => state.from);
export const getFeedTo = createSelector(feedSelector, state => state.to);

export const getLatestFeedError = createSelector(
  feedSelector,
  state => state.errorLatest,
);

export const getOlderFeedError = createSelector(
  feedSelector,
  state => state.errorOlder,
);

export const isLoadingLatestFeed = createSelector(
  feedSelector,
  state => state.loadingLatest,
);

export const isLoadingOlderFeed = createSelector(
  feedSelector,
  state => state.loadingOlder,
);

export const getNews = createGenericSelector(
  NEWS_NAMESPACE,
  'data',
  getNewsKey,
);

export const getNewsError = createGenericSelector(
  NEWS_NAMESPACE,
  'error',
  getNewsKey,
);

const consecutiveItemReducer = (
  itemType,
  maxConsecutive,
  maxRemoved = Number.MAX_VALUE,
) => {
  let curConsecutive = 0;
  let numRemoved = 0;
  // Skip item when there is already n of same type sorted before
  return (acc, item) => {
    curConsecutive = item.type === itemType ? curConsecutive + 1 : 0;
    if (curConsecutive > maxConsecutive && numRemoved < maxRemoved) {
      numRemoved++;
    } else {
      acc.push(item);
    }
    return acc;
  };
};

export const getVisibleResults = createSelector(
  [getFeatured, getFeedList, getNews, getFeedCursor],
  (featured = [], feedList, news, feedCursor) => {
    if (feedList.length === 0 || news == null) {
      return;
    }

    // Max news items in a row
    const MAX_CONSECUTIVE_NEWS = 2;
    // Don't allow more than a fifth of each feed block to be TR News
    const MAX_NEWS_RATIO = 0.2;

    const activeSlice = feedList.slice(feedCursor);

    return [
      ...featured,
      ...activeSlice
        // Map over each feed block and insert news
        .flatMap(({ from, to, results }) => {
          // Pick news items published within timeframe of the feed results
          const filteredNews = news.filter(item => {
            const d = getUnixTime(new Date(item.time));
            return d >= from && d <= to;
          });

          if (filteredNews.length === 0) {
            return results;
          }

          // Merge with news
          let newResults = [...results, ...filteredNews]
            // Sorted by time
            .sort((a, b) => new Date(b.time) - new Date(a.time))
            // We don't want to many news items after each other
            .reduce(consecutiveItemReducer('trNews', MAX_CONSECUTIVE_NEWS), [])
            // Remove items that has become featured
            .filter(({ id }) => !featured.some(f => f.id === id));

          // Remove some news items if the ratio is to0 high
          const newsRatio =
            (newResults.length - results.length) / results.length;
          if (newsRatio > MAX_NEWS_RATIO) {
            const numToRemove = Math.floor(results.length * MAX_NEWS_RATIO);
            let numOldRemoved = 0;
            // Remove some news items to lower the ratio.
            // Starting from the oldest,
            // first we drop consecutive items then least recently published
            newResults = newResults
              .reduceRight(consecutiveItemReducer('trNews', 1, numToRemove), [])
              .reduce((acc, item, _, source) => {
                if (
                  item.type === 'trNews' &&
                  numOldRemoved <
                    numToRemove - newResults.length + source.length
                ) {
                  numOldRemoved++;
                } else {
                  acc.push(item);
                }
                return acc;
              }, [])
              .reverse();
          }

          return newResults;
        }),
    ];
  },
);

export const hasNewResults = createSelector(feedSelector, feed => feed.hasNew);

// Disable scroll load after n loads, after we'll let the user click to load
export const isScrollLoadEnabled = createSelector(
  feedSelector,
  feed => feed.numOlder < 2,
);
