import { darken, tint } from 'polished';
import styled from 'styled-components';

import { colors } from 'utils/css-mixins';
import media from 'utils/media';

export const Button = styled.a`
  height: 36px;
  border-bottom: solid 2px ${darken(0.1, colors.buttonprimary)};
  padding: 0 10px;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: ${colors.buttonprimary};
  color: ${colors.white};
  border-radius: 2px;
  opacity: ${props => (props.disabled ? '.5' : '1')};
  transition: transform 100ms ease;

  &:hover {
    cursor: pointer;
  }

  &:active {
    border-bottom: 0;
    transform: translateY(2px);
    box-shadow: 0 0 1px ${darken(0.2, colors.buttonprimary)};
  }

  ${media.tablet`
    height: ${props => (!props.large ? '44px' : undefined)};
    font-size: 14px;
    line-height: 14px;
  `}

  ${props =>
    props.info &&
    `
    border-bottom-color: ${colors.border};
    background-color: ${colors.bginverted};
    color: ${colors.link};
  `}

  ${props =>
    props.inverse &&
    `
    border-bottom-color: ${colors.border};
    background-color: ${colors.bginverted};
    color: ${colors.buttonprimary};
  `}

  ${props =>
    props.dark &&
    `
    border-bottom-color: ${tint(0.1, colors.bgdark)};
    background-color: ${tint(0.15, colors.bgdark)};
    color: ${colors.white};
  `}

  ${props =>
    props.large &&
    `
    display: flex;
    width: 100%;
    justify-content: center;
    height: 44px;
    border-radius: 3px;
  `}
`;

export const ButtonGroup = styled.div`
  margin-top: 1rem;
  margin-bottom: 5px;

  &:first-child {
    margin-top: 0;
  }

  > * + * {
    margin-top: 0.5rem;
  }
`;
