import { isString } from '@trmediaab/zebra-utils';
import identity from 'lodash/identity';
import { call, put, select } from 'redux-saga/effects';

import { request } from 'utils/request';

import {
  fetchGenericFailure,
  fetchGenericStart,
  fetchGenericSuccess,
  updateGenericCache,
} from './actions';
import { defaultKey, isCached } from './selectors';

const ONE_SECOND = 1000;
const ONE_MINUTE = 60 * ONE_SECOND;
const ONE_HOUR = 60 * ONE_MINUTE;

// maxAge determines how old data can be before it should be re-fetched.
// It may be expressed as:
// - Infinity (never refetch),
// - 0 (always refetch),
// - a number in seconds,
// - a shorthand string, eg '30seconds', '1minute', '5hours'
export function* fetchGeneric({
  namespace,
  url,
  maxAge = '1minute',
  responseHandler = identity,
  key = defaultKey(),
  extract_id,
  options,
}) {
  let parsedMaxAge;
  if (maxAge === Number.POSITIVE_INFINITY || Number.isFinite(maxAge)) {
    parsedMaxAge = maxAge;
  } else if (isString(maxAge)) {
    const s = maxAge.includes('second')
      ? 'second'
      : maxAge.includes('minute')
      ? 'minute'
      : maxAge.includes('hour')
      ? 'hour'
      : undefined;
    if (s != null) {
      const n = Number.parseInt(
        maxAge.slice(0, Math.max(0, maxAge.indexOf(s))),
        10,
      );
      parsedMaxAge =
        (s === 'second' ? ONE_SECOND : s === 'minute' ? ONE_MINUTE : ONE_HOUR) *
        (Number.isFinite(n) ? n : 1);
    }
  }

  const cached =
    parsedMaxAge == null || parsedMaxAge === 0
      ? false
      : yield select(isCached, { maxAge: parsedMaxAge, url });

  if (!cached) {
    yield put(fetchGenericStart(namespace, key));

    try {
      const response = yield call(request, url, options);
      const data = responseHandler(response);
      const id = extract_id ? extract_id(data) : undefined;

      yield put(fetchGenericSuccess(namespace, key, data, id));

      if (
        parsedMaxAge === Number.POSITIVE_INFINITY ||
        Number.isFinite(parsedMaxAge)
      ) {
        yield put(updateGenericCache(url));
      }
    } catch (error) {
      yield put(fetchGenericFailure(namespace, key, error));
    }
  }
}
