import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors, spacing } from 'utils/css-mixins';
import media from 'utils/media';

import { NAV_TOGGLE_WIDTH, TABLET_BREAKPOINT } from './constants';

const leftPadding = 10;

const StyledButton = styled.button`
  border: 0;
  background-color: transparent;
  appearance: none;
  padding: 20px 0 20px ${leftPadding}px;

  ${media.min(TABLET_BREAKPOINT)`
    margin-left: ${spacing.siteGutter}px;
  `}

  div {
    margin: 0 auto;
    height: 2px;
    width: ${NAV_TOGGLE_WIDTH - leftPadding}px;
    background-color: ${colors.white};
    transition: transform 300ms ease;
  }

  div:first-child {
    transform: ${props => (props.open ? 'rotate(45deg)' : 'none')};
  }

  div:last-child {
    transform: ${props => (props.open ? 'rotate(-45deg)' : 'none')};
  }

  div + div {
    margin-top: ${props => (props.open ? '-2px' : '5px')};
  }

  &:focus {
    outline: none;
  }
`;

const NavToggle = props => {
  const { open, onClick } = props;
  return (
    <StyledButton onClick={onClick} open={open}>
      <div />
      <div />
      {!open && <div />}
    </StyledButton>
  );
};

NavToggle.propTypes = {
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default NavToggle;
