import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { fetchGeneric } from 'containers/GenericFetch/saga';
import { getRaceResults } from 'containers/Race/saga';
import { apiUrl } from 'utils/request';
import { subscribe } from 'utils/websocket';

import { NAMESPACE } from './constants';
import { selectRoundLeg } from './selectors';

function* resultSaga({ payload: { slug, leg_number } }) {
  if (leg_number) {
    yield getRoundLeg(slug, leg_number);

    const leg = yield select(selectRoundLeg, { slug, leg: leg_number });

    if (leg) {
      const race_number = leg.race.race_number;
      const track = leg.race.meet.track_slug;
      const date = leg.race.meet.race_date;

      yield call(getRaceResults, { track, date, race_number });
    }
  }
}

function* tipSaga({ payload: { slug, view, leg_number = 1 } }) {
  if (view === 'startlistor' || view === 'intervjuer') {
    yield getRoundLeg(slug, leg_number);
  }
}

function* getRoundLeg(slug, leg) {
  yield fetchGeneric({
    namespace: NAMESPACE,
    url: apiUrl(`roundleg/${slug}-${leg}`),
    maxAge: Number.POSITIVE_INFINITY,
    key: `${slug}-${leg}`,
  });

  const roundLeg = yield select(selectRoundLeg, { slug, leg });

  if (roundLeg && roundLeg.race) {
    yield put(subscribe(`race:${roundLeg.race.id}:travronden_spel`));
  }
}

export default function* rootSaga() {
  yield all([takeLatest('ROUND_TIP', tipSaga)]);
  yield all([takeLatest('ROUND_RESULT', resultSaga)]);
}
