import { tint } from 'polished';
import styled from 'styled-components';

import { colors } from 'utils/css-mixins';

const Wrapper = styled.div`
  max-width: 100%;
  overflow: auto;
`;
const StyledTable = styled.table`
  text-align: left;
  border-collapse: collapse;
  font-size: 12px;
  border-radius: 4px;

  thead th {
    border-color: ${tint(0.15, colors.primary)};
    border-bottom: 2px solid rgb(222, 226, 230);
    background-color: ${colors.primary};
    color: ${colors.white};
    vertical-align: bottom;
    white-space: nowrap;
  }

  td,
  th {
    padding: 10px 15px;
    border: 1px solid ${colors.border};
    vertical-align: top;
    white-space: nowrap;
  }
`;

const Table = props => (
  <Wrapper>
    <StyledTable {...props} />
  </Wrapper>
);

export default Table;
