import styled from 'styled-components';

import { colors } from 'utils/css-mixins';
import media from 'utils/media';

export const HorizontalSpacer = styled.div`
  margin-right: ${props => props.margin || 10}px;
  margin-left: ${props => props.margin || 10}px;
`;

export const VerticalSpacer = styled.div`
  margin-top: ${props => props.margin || 10}px;
`;

export const HorizontalRuler = styled.div`
  margin-top: ${props => props.margin || 10}px;
  margin-bottom: ${props => props.margin || 20}px;
  border-bottom: ${props => props.borderBottom || `solid 1px ${colors.border}`};

  ${media.desktop`
    margin-top: ${props => props.margin * 1.5 || 15}px;
    margin-bottom: ${props => props.margin * 2 || 30}px;
  `}
`;
