import Link from 'redux-first-router-link';

import logo from 'images/travronden-spel.svg';
import { colors, layout } from 'utils/css-mixins';
import media from 'utils/media';

const Logobar = () => (
  <header
    css={`
      background-color: ${colors.primary};
      box-shadow: 0 1px 4px 0 rgb(157 157 157 / 50%);
    `}
  >
    <div
      css={`
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: content-box;
        max-width: ${layout.oneColumn.maxWidth}px;
        padding: 0 ${layout.oneColumn.pageGutter}px;
        margin: 0 auto;
        height: 50px;
      `}
    >
      <Link to={{ type: 'HOME' }}>
        <img
          css={`
            width: 300px;
            height: auto;

            ${media.tablet`
              width: 380px;
            `}
          `}
          src={logo}
          alt="Travronden Spel"
        />
      </Link>
    </div>
  </header>
);

export default Logobar;
