import styled from 'styled-components';

import ErrorBoundary from 'components/ErrorBoundary';
import { spacing, stickyStyles } from 'utils/css-mixins';
import media from 'utils/media';

import KopAndelPromo from './KopAndelPromo';
import RoundList from './RoundList';

const Root = styled.div`
  > * + * {
    margin-top: ${spacing.siteGutter * 2}px;
  }

  ${media.desktop`
    ${stickyStyles(10)}
  `}
`;

const Sidebar = () => (
  <ErrorBoundary>
    <Root>
      <RoundList />
      <KopAndelPromo />
    </Root>
  </ErrorBoundary>
);

export default Sidebar;
