import styled from 'styled-components';

import { colors } from 'utils/css-mixins';

export const PageLabel = styled.div`
  color: ${colors.textmeta};
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
`;

export const H1 = styled.h1`
  color: ${props => (props.color ? props.color : colors.bodyText)};
  font-size: ${({ size: fontSize }) => fontSize || 36}px;
  font-weight: bold;
`;

export const H2 = styled.h2`
  color: ${props => (props.color ? props.color : colors.bodyText)};
  font-size: ${({ size: fontSize }) => fontSize || 26}px;
  font-weight: ${props => (props.bold ? 700 : 400)};
  margin: 5px 0 10px;
`;

export const H3 = styled.h3`
  color: ${props => (props.color ? props.color : colors.bodyText)};
  font-size: ${({ size: fontSize }) => fontSize || 22}px;
  font-weight: 600;
  margin: 5px 0 10px;
`;

export const H4 = styled.h4`
  color: ${props => (props.color ? props.color : colors.bodyText)};
  font-size: ${({ size: fontSize }) => fontSize || 26}px;
  font-weight: 400;
  margin: 5px 0 10px;
`;
