import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import * as React from 'react';

import config from 'config';

Bugsnag.start({
  apiKey: config.BUGSNAG_API_KEY,
  appVersion: process.env.REACT_APP_VERSION,
  releaseStage: process.env.NODE_ENV,
  enabledReleaseStages: ['production'],
  maxBreadcrumbs: 30,
  plugins: [new BugsnagPluginReact(React)],
  onError: event => {
    // Log only production errors
    if (process.env.REACT_APP_VERSION == null) {
      return false;
    }

    const { originalError } = event;

    // Discard error caused by ad script. This is probably an unhandled promise
    // rejection that was caused by tracking protection in the browser.
    if (
      originalError.target != null &&
      originalError.target.tagName === 'SCRIPT' &&
      originalError.target.src.includes('gpt.js')
    ) {
      return false;
    }
  },
});

export default Bugsnag;
