export const debounce = fn => {
  // Setup a timer
  let timeout;

  // Return a function to run debounced
  return (...args) => {
    // If there's a timer, cancel it
    if (timeout) {
      window.cancelAnimationFrame(timeout);
    }

    // Setup the new requestAnimationFrame()
    timeout = window.requestAnimationFrame(() => {
      fn.apply(this, args);
    });
  };
};

// Ordered by priority
export const betTypes = [
  'V75',
  'V86',
  'GS75',
  'V64',
  'V65',
  'V5',
  'V4',
  'V3',
  'TRIO',
  'DD',
  'LD',
];
