import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import Ad from 'components/Ad';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import PageMeta, { defaultMeta } from 'components/PageMeta';

/* import Promo from 'containers/Promo'; */
import routesMap from '../../routes';
import Logobar from './Logobar';
import Sidebar from './Sidebar';
import { AppWrapper, Aside, Main, PageContainer } from './styles';

class Page extends Component {
  static propTypes = {
    location: PropTypes.object,
  };

  render() {
    const { location } = this.props;
    const route = routesMap[location.type];
    const {
      pageBackgroundVariant,
      pageVariant,
      panoramaEnabled,
      component: PageComponent,
    } = route;

    return (
      <AppWrapper role="document" backgroundVariant={pageBackgroundVariant}>
        <PageMeta meta={defaultMeta}>
          <link
            rel="canonical"
            href={`https://travrondenspel.se${location.pathname}`}
          />
        </PageMeta>

        {pageVariant !== 'stripped' && <Navbar />}
        {pageVariant === 'stripped' && <Logobar />}

        {/* TODO - should we keep this? */}
        {/*<Promo />*/}

        {panoramaEnabled && (
          <Ad
            deviceFilter="desktopOnly"
            placement="panorama"
            refreshKey={`desktop_${location.type}`}
          />
        )}

        <PageContainer>
          <Main hasPanorama={panoramaEnabled}>
            {panoramaEnabled && (
              <Ad
                deviceFilter="mobileOnly"
                placement="panorama"
                refreshKey={`mobile_${location.type}`}
                wrap={content => (
                  <div
                    css={
                      location.type !== 'HOME'
                        ? `margin-bottom: 24px;`
                        : undefined
                    }
                  >
                    {content}
                  </div>
                )}
              />
            )}
            <PageComponent location={location} />
          </Main>
          {pageVariant !== 'stripped' && (
            <Aside hasPanorama={panoramaEnabled}>
              <Sidebar />
            </Aside>
          )}
        </PageContainer>

        <Footer />
      </AppWrapper>
    );
  }
}

const mapStateToProps = state => ({
  location: state.location,
});

export default connect(mapStateToProps)(Page);
