import Link from 'redux-first-router-link';
import styled from 'styled-components';

import { Svg } from 'components/Elements';
import logo from 'images/travronden-spel.svg';
import media from 'utils/media';

import { BRANDMARK_WIDTH, TABLET_BREAKPOINT } from './constants';

const LinkedWordmark = styled(Link)`
  position: relative;
  width: 340px;
  height: 40px;
  margin: 10px auto 10px 0;
  padding: 0;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-position: 0 center;
  background-size: 320px auto;
  text-indent: -999px;

  ${media.max('xs')`
    background-size: 240px auto;
    width: 260px;
  `}

  ${media.desktop`
    background-size: 450px auto;
    width: 470px;
  `}
`;

const LinkedBrandmark = styled(Link)`
  img {
    width: ${BRANDMARK_WIDTH}px;
    height: auto;
  }

  ${media.min(TABLET_BREAKPOINT)`
    display: none;
  `}
`;

export const Wordmark = () => (
  <LinkedWordmark to={{ type: 'HOME' }}>Travronden Spel</LinkedWordmark>
);

export const Brandmark = ({ className }) => (
  <LinkedBrandmark className={className} to={{ type: 'HOME' }}>
    <Svg name="t-white" />
  </LinkedBrandmark>
);
