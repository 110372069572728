import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Menu,
} from '@styled-icons/material';
import { tint } from 'polished';
import { Fragment, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import Link from 'components/Link';
import RoundLink from 'components/RoundLink';
import config from 'config';
import { selectUser } from 'containers/Auth/selectors';
import { getLoginUrl } from 'containers/Auth/utils';
import { selectActive } from 'containers/Round/selectors';
import useBlockScroll from 'hooks/useBlockScroll';
import useWatchScroll from 'hooks/useWatchScroll';
import { colors, layout, navHeights, zIndex } from 'utils/css-mixins';
import media from 'utils/media';

import DropDown from '../DropDown';
import { Brandmark, Wordmark } from './Brand';
import {
  BRANDMARK_WIDTH,
  NAV_TOGGLE_WIDTH,
  SCROLL_THRESHOLD,
  TABLET_BREAKPOINT,
} from './constants';
import NavLinks, { StyledLink } from './NavLinks';
import NavToggle from './NavToggle';
import { NavToggleWrapper } from './styles';
import TopDivItems from './TopDivItems';

// Wrap in Styled so we can use override styles below
// See https://www.styled-components.com/docs/advanced#referring-to-other-components
const StyledBrandmark = styled(Brandmark)``;

const Bar = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  ${props => (props.open ? `position: sticky;` : `position: absolute;`)}
  z-index: ${zIndex.navbar};
  left: 0;
  right: 0;
  top: ${navHeights.topRow}px;
  border-bottom: 1px solid ${colors.bgsitecomplementary};
  background-color: ${colors.primary};
  line-height: 30px;

  .account-dropdown + .account-dropdown {
    margin-top: 10px;
  }

  ${media.max(1079)`
    .dropdown {
      a {
        color: ${colors.text};
      }
    }
  `}

  ${media.min(TABLET_BREAKPOINT)`
    position: sticky;
    top: 0;
    padding-right: 0;
    .dropdown + .dropdown {
      margin-top: 10px;
      margin-left: 0;

      a {
        padding: 0;
        margin: 0;
        color: inherit;
        line-height: 39px;
        letter-spacing: -0.09px;
        font-weight: normal;
        font-size: 14px;
      }
      li:last-child {
        border-style: none;
      }
    }
  `}
`;

const MiddleBar = styled.div`
  height: ${navHeights.middleRow}px;
  width: 100%;
  align-items: center;
  padding: 0 ${layout.oneColumn.pageGutter}px;
  ${props =>
    props.scroll > SCROLL_THRESHOLD ? `display: none;` : `display: flex;`}

  ${media.min(TABLET_BREAKPOINT)`
    display: flex;
    height: ${navHeights.middleRowTabletUp}px;
  `}

  ${media.desktop`
    padding: 0 ${layout.twoColumn.pageGutter}px;
    max-width: ${layout.twoColumn.maxWidth}px;
    margin-left: auto;
    margin-right: auto;
  `}
`;

const LowerBar = styled.div`
  flex-wrap: wrap;
  width: 100%;
  background-color: ${colors.white};
  height: 0;

  .account-dropdown + .account-dropdown {
    margin-top: 10px;
  }

  ${media.min(TABLET_BREAKPOINT)`
    display: flex;
    height: ${navHeights.lowerRow}px;
  `}

  ${media.max(1079)`
    white-space: nowrap;
  `}
`;

const TopDiv = styled.div`
  ${props => (props.open ? `position: fixed;` : `position: sticky;`)}
  z-index: ${zIndex.navbar + 10};
  top: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: ${navHeights.topRow}px;
  line-height: 30px;
  margin-bottom: ${props => (props.open ? 0 : navHeights.middleRow)}px;
  padding: 0 ${layout.oneColumn.pageGutter}px;
  overflow: hidden;
  ${props =>
    props.scroll > SCROLL_THRESHOLD
      ? `border-bottom: 1px solid ${tint(0.15, colors.primary)};`
      : `border-bottom-style: none;`}
  background-color: ${props =>
    props.scroll > SCROLL_THRESHOLD ? colors.primary : colors.white};
  transition: background-color 150ms linear;

  ${NavToggleWrapper} {
    margin-right: ${props =>
      props.scroll > SCROLL_THRESHOLD ? 0 : `${-1 * NAV_TOGGLE_WIDTH}px;`};
    pointer-events: ${props =>
      props.scroll > SCROLL_THRESHOLD ? undefined : 'none'};
    transition: margin-right 150ms linear;

    div {
      background-color: ${colors.white};
    }
  }

  ${StyledBrandmark} {
    margin-left: ${props =>
      props.scroll > SCROLL_THRESHOLD ? 0 : `${-1 * BRANDMARK_WIDTH}px`};
    pointer-events: ${props =>
      props.scroll > SCROLL_THRESHOLD ? undefined : 'none'};
    transition: margin-left 150ms linear;
  }

  ${media.min(TABLET_BREAKPOINT)`
    display: none;
  `}
`;

const DesktopDiv = styled.div`
  display: none;
  align-items: center;
  margin-bottom: 1px;

  > * + * {
    margin-left: 10px;
  }

  ${media.min(TABLET_BREAKPOINT)`
    display: flex;
  `}
`;

const MoreMenu = styled.div`
  .dropdown + .dropdown {
    margin-left: 10px;
  }

  ${media.min(TABLET_BREAKPOINT)`
    margin-right: auto;
  `}
`;

const ContactDiv = styled.div`
  margin-top: 10px;

  ${media.min(TABLET_BREAKPOINT)`
    display: none;
  `}

  ${StyledLink} {
    font-size: 13px;
    font-weight: 600;
  }
`;

const LowerBarTop = styled.div`
  padding-top: 15px;

  ${media.min(TABLET_BREAKPOINT)`
    padding-top: 0;
    display: flex;

    > * + * {
      margin-left: 20px;
    }

    > :last-child {
      margin-right: 20px;
    }
  `}

  ${media.max(TABLET_BREAKPOINT - 1)`
    ${StyledLink} {
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
      margin-top: 12px;
      border-bottom: 1px solid ${colors.bgsitecomplementary};
      padding-bottom: 15px;
      &:last-child {
        border: 0;
      }
    }
  `}
`;

const StyledMenu = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  border-right: 2px solid ${colors.bgsitecomplementary};
  border-left: 2px solid ${colors.bgsitecomplementary};

  span {
    margin-left: 4px;
  }

  svg {
    height: 25px;
    width: 25px;
  }
`;

const StyledKeyboardArrowDown = styled(KeyboardArrowDown)`
  color: ${colors.lightgrey};
`;

const StyledKeyboardArrowUp = styled(KeyboardArrowUp)`
  color: ${colors.lightgrey};
`;

const mapStateToProps = createStructuredSelector({
  user: selectUser,
  rounds: selectActive,
  currentPage: state => state.location.type,
});

export default connect(mapStateToProps)(props => {
  const { user, rounds, currentPage, logOut } = props;
  const [open, toggleNavLinks] = useState(false);
  const [dropdownOpen, toggleDropdownArrow] = useState(false);
  const scrollAmount = useWatchScroll({ enabled: !open });
  const blockScrollWidth = TABLET_BREAKPOINT - 1;

  useBlockScroll({
    maxWindow: blockScrollWidth,
    enabled: open,
  });

  const toggleDropdownHandler = useCallback(() => {
    toggleDropdownArrow(open => !open);
  }, []);

  const getTipLinkRoute = (startlist = false) => {
    if (rounds) {
      const v75link = rounds.find(round => round.bet_type === 'V75');

      if (v75link) {
        const route = {
          type: 'ROUND_TIP',
          payload: { slug: v75link.slug },
        };

        if (startlist) {
          route.payload = {
            ...route.payload,
            view: 'startlistor',
            leg_number: 1,
          };
        }

        return route;
      }
    }
    return {
      type: 'HOME',
    };
  };

  const LinkItem = props => (
    <StyledLink onClick={() => toggleNavLinks(false)} as={Link} {...props} />
  );

  const RoundLinkItem = RoundLink(LinkItem);

  const navToggle = (
    <NavToggleWrapper>
      <NavToggle onClick={() => toggleNavLinks(!open)} open={open} />
    </NavToggleWrapper>
  );

  return (
    <Fragment>
      <TopDiv open={open} scroll={scrollAmount}>
        <StyledBrandmark />
        <TopDivItems
          user={user}
          open={open}
          scrollAmount={scrollAmount}
          logout={logOut}
        />
        {navToggle}
      </TopDiv>
      <Bar open={open}>
        <MiddleBar scroll={scrollAmount} open={open}>
          <Wordmark />
          {navToggle}
          <DesktopDiv>
            <TopDivItems
              user={user}
              open={open}
              scrollAmount={scrollAmount}
              logout={logOut}
            />
          </DesktopDiv>
        </MiddleBar>
        <LowerBar>
          <NavLinks open={open}>
            <LowerBarTop>
              <LinkItem to={{ type: 'HOME' }} isActive={currentPage === 'HOME'}>
                Hem
              </LinkItem>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              <span
                role="button"
                tabIndex="0"
                css={`
                  user-select: none;
                  &:focus {
                    outline: none;
                  }
                `}
                onClick={toggleDropdownHandler}
              >
                <DropDown
                  link={
                    <Fragment>
                      <span>V75</span>
                      {dropdownOpen ? (
                        <StyledKeyboardArrowUp />
                      ) : (
                        <StyledKeyboardArrowDown />
                      )}
                    </Fragment>
                  }
                  elem={StyledLink}
                  items={[
                    <RoundLinkItem to={getTipLinkRoute()} key="v75tips">
                      V75 Tips
                    </RoundLinkItem>,
                    <LinkItem to={getTipLinkRoute(true)} key="v75startlistor">
                      V75 Startlistor
                    </LinkItem>,
                    <LinkItem
                      key="v75resultat"
                      to={{
                        type: 'ROUND_RESULTS',
                        payload: { bet_type: 'V75' },
                      }}
                    >
                      V75 Resultat
                    </LinkItem>,
                  ]}
                />
              </span>
              <LinkItem
                to={{ type: 'NEWS_LIST' }}
                isActive={currentPage.includes('NEWS')}
              >
                Nyheter
              </LinkItem>
              <LinkItem
                to={{ type: 'CHRONICLE_LIST' }}
                isActive={currentPage.includes('CHRONICLE')}
              >
                Krönikor
              </LinkItem>
              <LinkItem
                to={{ type: 'SHARED_BETS' }}
                isActive={currentPage.includes('SHARED_BETS')}
              >
                Andelsspel
              </LinkItem>
              <LinkItem
                to={{ type: 'TV_LIST' }}
                isActive={currentPage.includes('TV')}
              >
                TV
              </LinkItem>
              <LinkItem
                to={{ type: 'RACE_LIST' }}
                isActive={currentPage.includes('RACE')}
              >
                Startlistor & Resultat
              </LinkItem>
              <LinkItem
                to={{ type: 'ALL_STARTS' }}
                isActive={currentPage === 'ALL_STARTS'}
              >
                Till start
              </LinkItem>
            </LowerBarTop>
            {open ? (
              <ContactDiv>
                <LinkItem
                  href="https://etidning.travronden.se"
                  target="_blank"
                  as="a"
                >
                  E-tidning
                </LinkItem>
                <LinkItem
                  to={{
                    type: 'PAGE',
                    payload: { slug: 'travronden-spel' },
                  }}
                >
                  Om Travronden Spel
                </LinkItem>
                <LinkItem
                  href={`${process.env.REACT_APP_TRAVRONDEN_URL}/faq`}
                  target="_blank"
                  as="a"
                >
                  Kundservice
                </LinkItem>
                <LinkItem
                  href="https://shop.travronden.se/integritetspolicy"
                  target="_blank"
                  as="a"
                >
                  Integritetspolicy
                </LinkItem>
                {user ? (
                  <LinkItem as="a" target="_blank" href={config.USERPAGES_URL}>
                    Mitt konto
                  </LinkItem>
                ) : (
                  <LinkItem as="a" href={getLoginUrl()}>
                    Logga in
                  </LinkItem>
                )}
              </ContactDiv>
            ) : (
              <MoreMenu>
                <DropDown
                  right
                  link={
                    <StyledMenu>
                      <Menu />
                      <span>Meny</span>
                    </StyledMenu>
                  }
                  elem={StyledLink}
                  items={[
                    <LinkItem
                      key="etidning"
                      href="https://etidning.travronden.se"
                      target="_blank"
                      as="a"
                    >
                      E-tidning
                    </LinkItem>,
                    <LinkItem
                      key="omtravrondenspel"
                      to={{
                        type: 'PAGE',
                        payload: { slug: 'travronden-spel' },
                      }}
                    >
                      Om Travronden Spel
                    </LinkItem>,
                    <LinkItem
                      key="kundservice"
                      href={`${process.env.REACT_APP_TRAVRONDEN_URL}/faq`}
                      target="_blank"
                      as="a"
                    >
                      Kundservice
                    </LinkItem>,
                    <LinkItem
                      key="integritetspolicy"
                      href="https://www.trmedia.se/integritetspolicy"
                      target="_blank"
                      as="a"
                    >
                      Integritetspolicy
                    </LinkItem>,
                  ]}
                />
              </MoreMenu>
            )}
          </NavLinks>
        </LowerBar>
      </Bar>
    </Fragment>
  );
});
