import styled from 'styled-components';

import { colors, layout } from 'utils/css-mixins';
import media from 'utils/media';

import { TABLET_BREAKPOINT } from './constants';

const NavLinks = styled.div`
  width: 100%;
  display: ${props => (props.open ? 'block' : 'none')};
  background-color: ${colors.white};
  align-items: center;
  padding: 0 ${layout.oneColumn.pageGutter}px;

  ${media.max(TABLET_BREAKPOINT - 1)`
    position: absolute;
    height: 100vh;
    .dropdown {
      background-color: ${colors.white};
      position: static;
      width: 100%;
      height: 100%;
    }
  `}

  ${media.min(TABLET_BREAKPOINT)`
    height: 100%;
    width: auto;
    display: flex;
    flex-grow: 1;
  `}

  ${media.desktop`
    padding: 0 ${layout.twoColumn.pageGutter}px;
    max-width: ${layout.twoColumn.maxWidth}px;
    margin-left: auto;
    margin-right: auto;
  `}
`;

export const StyledLink = styled.a`
  padding-top: 3px;
  padding-bottom: 3px;
  display: block;
  color: ${colors.bodyText};
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;

  &:active {
    color: ${colors.primary};
  }

  &:hover {
    cursor: pointer;
  }

  svg {
    width: 20px;
  }

  ${media.min(TABLET_BREAKPOINT)`
    padding: 0;
    background-color: transparent;
    color: ${colors.text};
    line-height: 40px;
    border-bottom: 3px solid transparent;
    margin-top: 3px;

    &:hover {
      cursor: pointer;
      border-bottom: 3px solid ${colors.primary};
    }

    ${props =>
      props.isActive
        ? `border-bottom: 3px solid ${colors.primary};`
        : `border-bottom: 3px solid transparent;`}
  `}
`;

export default NavLinks;
