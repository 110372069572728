import createReducer from '@ikhsaan/create-reducer';
import Immutable from 'seamless-immutable';

import {
  CLOSE_INTERVIEW_ARCHIVE_SUCCESS,
  FETCH_INTERVIEW_ARCHIVE_FAILURE,
  FETCH_INTERVIEW_ARCHIVE_START,
  FETCH_INTERVIEW_ARCHIVE_SUCCESS,
  LOAD_INTERVIEW_ARCHIVE_CACHE_SUCCESS,
} from './constants';
import { getInterviewArchive } from './selectors';

const initialState = Immutable({
  fetching: false,
  error: undefined,
  start_id: null,
  horse_id: null,
  race_start_time: '',
  page: 1,
  results: [],
  count: 0,
});

const Reducer = createReducer(initialState, {
  [CLOSE_INTERVIEW_ARCHIVE_SUCCESS](state) {
    return state
      .setIn(['fetching'], false)
      .setIn(['error'])
      .setIn(['start_id'], null)
      .setIn(['horse_id'], null)
      .setIn(['race_start_time'], '')
      .setIn(['page'], 1)
      .setIn(['results'], [])
      .setIn(['count'], 0);
  },
  [FETCH_INTERVIEW_ARCHIVE_START](
    state,
    { start_id, horse_id, race_start_time, page },
  ) {
    return state
      .setIn(['fetching'], true)
      .setIn(['error'])
      .setIn(['start_id'], start_id)
      .setIn(['horse_id'], horse_id)
      .setIn(['race_start_time'], race_start_time)
      .setIn(['page'], page);
  },
  [FETCH_INTERVIEW_ARCHIVE_SUCCESS](
    state,
    { start_id, horse_id, race_start_time, page, results, count },
  ) {
    const newState = state
      .setIn(['fetching'], false)
      .setIn(['error'])
      .setIn(['start_id'], start_id)
      .setIn(['horse_id'], horse_id)
      .setIn(['race_start_time'], race_start_time)
      .setIn(['page'], page)
      .setIn(['Interview', race_start_time, horse_id, 'pages', page], results)
      .setIn(['Interview', race_start_time, horse_id, 'count'], count);

    const data = getInterviewArchive(newState.Interview, {
      horse_id: horse_id,
      race_start_time: race_start_time,
      page: page,
    });

    if (typeof data === 'undefined') {
      return newState.setIn(['results'], []).setIn(['count'], 0);
    }

    return newState
      .setIn(['results'], data.results)
      .setIn(['count'], data.count);
  },
  [FETCH_INTERVIEW_ARCHIVE_FAILURE](state, { error }) {
    return state
      .setIn(['fetching'], false)
      .setIn(['error'], error)
      .setIn(['start_id'], null)
      .setIn(['horse_id'], null)
      .setIn(['race_start_time'], '')
      .setIn(['page'], 1)
      .setIn(['results'], [])
      .setIn(['count'], 0);
  },
  [LOAD_INTERVIEW_ARCHIVE_CACHE_SUCCESS](
    state,
    { start_id, horse_id, race_start_time, page },
  ) {
    const newState = state
      .setIn(['fetching'], false)
      .setIn(['error'])
      .setIn(['start_id'], start_id)
      .setIn(['horse_id'], horse_id)
      .setIn(['race_start_time'], race_start_time)
      .setIn(['page'], page);

    const data = getInterviewArchive(newState.Interview, {
      horse_id: horse_id,
      race_start_time: race_start_time,
      page: page,
    });

    if (typeof data === 'undefined') {
      return newState.setIn(['results'], []).setIn(['count'], 0);
    }

    return newState
      .setIn(['results'], data.results)
      .setIn(['count'], data.count);
  },
});

export default Reducer;
