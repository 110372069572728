import 'react-datepicker/dist/react-datepicker.css';

import { createGlobalStyle, css } from 'styled-components';

import GuardianEgyptianWebLightEot from './fonts/guardianegypweb-light.eot';
import GuardianEgyptianWebLightSvg from './fonts/guardianegypweb-light.svg';
import GuardianEgyptianWebLightTtf from './fonts/guardianegypweb-light.ttf';
import GuardianEgyptianWebLightWoff from './fonts/guardianegypweb-light.woff';
import GuardianEgyptianWebSemiBoldEot from './fonts/guardianegypweb-semibold.eot';
import GuardianEgyptianWebSemiBoldSvg from './fonts/guardianegypweb-semibold.svg';
import GuardianEgyptianWebSemiBoldTtf from './fonts/guardianegypweb-semibold.ttf';
import GuardianEgyptianWebSemiBoldWoff from './fonts/guardianegypweb-semibold.woff';
import { colors, fonts } from './utils/css-mixins';

const defaultStyles = css`
  @font-face {
    font-family: 'GuardianEgyptian';
    src: url('${GuardianEgyptianWebSemiBoldEot}');
    src: url('${GuardianEgyptianWebSemiBoldEot}?#iefix')
        format('embedded-opentype'),
      url('${GuardianEgyptianWebSemiBoldWoff}') format('woff'),
      url('${GuardianEgyptianWebSemiBoldTtf}') format('truetype'),
      url('${GuardianEgyptianWebSemiBoldSvg}') format('svg');
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'GuardianEgyptian';
    src: url('${GuardianEgyptianWebLightEot}');
    src: url('${GuardianEgyptianWebLightEot}?#iefix')
        format('embedded-opentype'),
      url('${GuardianEgyptianWebLightWoff}') format('woff'),
      url('${GuardianEgyptianWebLightTtf}') format('truetype'),
      url('${GuardianEgyptianWebLightSvg}') format('svg');
    font-style: normal;
    font-weight: 300;
    font-stretch: normal;
  }

  html {
    -ms-overflow-style: scrollbar;
    line-height: 1.4;
    font-size: 16px;
  }

  body {
    font-family: ${fonts.primary};
    background: ${colors.bgsite};
    color: ${colors.bodyText};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    text-rendering: optimizeLegibility;
  }

  p + p {
    margin-top: 1.5rem;
  }

  b,
  strong {
    font-weight: 600;
  }

  i,
  em {
    font-style: italic;
  }

  a {
    color: ${colors.link};
    text-decoration: none;
    transition: all 200ms ease;
  }

  th {
    font-weight: 600;
  }
`;

const reactDatePickerPopper = css`
  .react-datepicker-popper {
    .react-datepicker {
      border-color: ${colors.border};
      font-family: inherit;
    }

    .react-datepicker__header {
      border-bottom-color: ${colors.border};
      background-color: ${colors.bgsitecomplementary};
    }

    .react-datepicker__current-month {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      text-transform: capitalize;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range {
      background-color: ${colors.primary};
    }

    .react-datepicker__day--outside-month {
      color: ${colors.textmeta};
    }

    .react-datepicker .react-datepicker__triangle {
      left: 50%;
    }
  }
`;

export default createGlobalStyle`
  ${defaultStyles}
  ${reactDatePickerPopper}
`;
