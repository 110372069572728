import styled from 'styled-components';

import { colors, spacing } from 'utils/css-mixins';
import media from 'utils/media';

const Fact = styled.div`
  font-size: 14px;
  line-height: 20px;
`;

const FactItemsRow = styled.div`
  margin: 5px 0;

  ${props =>
    props.highlightValues &&
    `
    font-weight: 600;

    strong {
      font-weight: normal;
    }
  `}
`;

const FactItemsLabel = styled.div`
  margin-bottom: 5px;
  font-weight: 600;
`;

export const FactItems = ({ label, rows = [], ...rest }) => (
  <Fact {...rest}>
    {label && <FactItemsLabel>{label}</FactItemsLabel>}

    {rows.map(({ label, value }) => (
      <FactItemsRow key={label} {...rest}>
        <strong>{label}:</strong> {value}
      </FactItemsRow>
    ))}
  </Fact>
);

const StyledFactItemsGroup = styled.div`
  & + & {
    border-top: solid 1px ${colors.border};
    margin-top: ${spacing.siteGutter * 1.5}px;
    padding-top: ${spacing.siteGutter * 1.5}px;

    ${media.desktop`
    margin-top: ${spacing.siteGutter * 2}px;
    padding-top: ${spacing.siteGutter * 2}px;
  `}
  }

  > div + div {
    margin-top: ${spacing.siteGutter * 2}px;
  }

  p + p {
    margin-top: 0.75rem;
  }
`;

export const FactItemsGroup = ({ children }) => (
  <StyledFactItemsGroup>{children}</StyledFactItemsGroup>
);

export const FactHeading = styled.h2`
  margin-bottom: 12px;
  color: ${colors.primary};
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;

  ${media.desktop`
    font-size: 21px;
    line-height: 27px;
  `}
`;

export const FactSubHeading = styled.h3`
  margin: ${spacing.siteGutter * 1.5}px 0 12px;
  color: ${colors.primary};
  font-size: 18px;
  line-height: 24px;
`;
