import styled from 'styled-components';

import { colors } from 'utils/css-mixins';

export default styled.hr`
  margin-top: ${props => props.marginTop || '1.5rem'};
  margin-bottom: ${props => props.marginBottom || '1.5rem'};
  border: none;
  height: 1px;
  background-color: ${colors.border};
`;
