import { LocalTokenStorage, TruthClient } from '@trmediaab/truth';
import { delay } from 'redux-saga';

const REFRESH_TIMEOUT = 100; // ms

class ExtendedTruthClient extends TruthClient {
  constructor(props) {
    super(props);

    this._refreshPromise = undefined;
  }

  // On many pages we make many API requests at the same time. Debouncing makes
  // it possible to only make one refresh request instead of one per API
  // request.
  refreshDebounced() {
    if (this._refreshPromise != null) {
      return this._refreshPromise;
    }

    this._refreshPromise = delay(REFRESH_TIMEOUT)
      .then(() => this.refresh())
      .then(
        response => {
          this._refreshPromise = undefined;
          return response;
        },
        error => {
          this._refreshPromise = undefined;
          return Promise.reject(error);
        },
      );

    return this._refreshPromise;
  }
}

export default new ExtendedTruthClient({
  host: '',
  storage: new LocalTokenStorage('truth', window.localStorage),
  saveJwtToken: false,
});
