import { useWindowSize } from '@trmediaab/zebra-hooks';
import PropTypes from 'prop-types';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { AdSlot, DFPManager } from 'react-dfp';
import styled from 'styled-components';

import { colors, layout } from 'utils/css-mixins';
import media, { breakpoints } from 'utils/media';

import { AD_UNITS } from './adUnits';

const AD_STATES = {
  INITIAL: 'INITIAL',
  FILLED: 'FILLED',
  EMPTY: 'EMPTY',
};

const placements = {
  panorama: 'panorama',
  feed1: 'feed1',
  feed2: 'feed2',
  feed3: 'feed3',
};

const Root = styled.div`
  overflow: hidden;
  background-color: ${colors.bgsiteSecondary};
  margin-left: ${-1 * layout.oneColumn.pageGutter}px;
  margin-right: ${-1 * layout.oneColumn.pageGutter}px;
  padding: 16px 0 20px;

  ${media.tablet`
    background-color: #e1e1e1;
  `}

  ${media.desktop`
    margin-left: 0;
    margin-right: 0;
    padding: 20px 0 30px;

    main & {
      padding: 0;
    }
  `}

  ${media.tablet`
    main &:not(:first-child) {
      background: inherit;
      margin-left: 0;
      margin-right: 0;
    }
  `}
`;

const Wrapper = styled.div`
  display: ${props => (props.slotEmpty ? 'none' : 'flex')};
  visibility: ${props => (props.slotFilled ? 'visible' : 'hidden')};
  justify-content: center;

  ${media.max(980)`
    margin: 0 ${layout.oneColumn.pageGutter}px;
  `}
`;

const StyledAdSlot = styled(AdSlot)`
  min-height: 50px;

  ${media.tablet`
    min-height: 90px;
  `}

  ${media.desktop`
    min-height: 120px;
  `}
`;

const StyledLabel = styled.p`
  font-size: 10px;
  color: ${colors.textmeta};
  margin: 0;
  margin-bottom: 6px;
  line-height: 1;
`;

const MemoizedAdSlot = memo(props => <StyledAdSlot {...props} />);

let adCounter = 0;

const Ad = ({ placement, deviceFilter, refreshKey, wrap }) => {
  const [adState, setAdstate] = useState(AD_STATES.INITIAL);
  const windowSize = useWindowSize(breakpoints);
  const lastWindowSize = useRef(windowSize);
  const lastRefreshKey = useRef(refreshKey);
  const idRef = useRef(null);

  const { name, sizes, sizeMapping } = AD_UNITS[placement];

  if (idRef.current == null) {
    idRef.current = `ad_${adCounter++}`;
  }

  // Refresh ad when window is resized
  useEffect(() => {
    if (
      sizeMapping != null &&
      lastWindowSize?.current.width !== windowSize.width
    ) {
      DFPManager.refresh(idRef.current);
      lastWindowSize.current = windowSize;
    }
  }, [windowSize, sizeMapping]);

  // Refresh ad when refresh key changes
  useEffect(() => {
    if (sizeMapping != null && lastRefreshKey.current !== refreshKey) {
      DFPManager.refresh(idRef.current);
      lastRefreshKey.current = refreshKey;
    }
  }, [refreshKey, sizeMapping]);

  const onSlotRender = useCallback(e => {
    if (e.event.isEmpty) {
      setAdstate(AD_STATES.EMPTY);
    } else {
      setAdstate(AD_STATES.FILLED);
    }
  }, []);

  // Skip mobile only on larger screen
  if (deviceFilter === 'mobileOnly' && windowSize.breakpoints.desktop) {
    return null;
  }

  // Skip desktop only on smaller screen
  if (deviceFilter === 'desktopOnly' && !windowSize.breakpoints.desktop) {
    return null;
  }

  return wrap(
    <Root>
      <Wrapper
        slotEmpty={adState === AD_STATES.EMPTY}
        slotFilled={adState === AD_STATES.FILLED}
      >
        <div>
          <StyledLabel>Annons</StyledLabel>
          <MemoizedAdSlot
            adUnit={name}
            slotId={idRef.current}
            onSlotRender={onSlotRender}
            sizes={sizes}
            sizeMapping={sizeMapping}
          />
        </div>
      </Wrapper>
    </Root>,
  );
};

Ad.propTypes = {
  placement: PropTypes.oneOf(Object.keys(placements)).isRequired,
  deviceFilter: PropTypes.oneOf(['none', 'mobileOnly', 'desktopOnly']),
  refreshKey: PropTypes.string.isRequired,
  wrap: PropTypes.func,
};

Ad.defaultProps = {
  deviceFilter: 'none',
  wrap: content => content,
};

export default memo(Ad);
