import styled from 'styled-components';

import media from 'utils/media';

import { TABLET_BREAKPOINT } from './constants';

export const NavToggleWrapper = styled.div`
  display: block;

  ${media.min(TABLET_BREAKPOINT)`
    display: none;
  `}
`;
