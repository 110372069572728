import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 2em auto;
  max-width: 800px;
  position: relative;
`;

const Alert = styled.div`
  background: rgb(255, 131, 116);
  border: solid 1px #000;
  padding: 15px;
`;

const Error = ({ error }) => {
  console.error(error);
  const text = error.detail ?? error.message;

  return (
    <Wrapper>
      <Alert>{text}</Alert>
    </Wrapper>
  );
};

Error.propTypes = {
  error: PropTypes.object,
};

export default Error;
