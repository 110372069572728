import { createGenericSelector } from '../GenericFetch/selectors';
import { NAMESPACE } from './constants';

const getKey = props => props.id || props.slug;

export const selectRound = createGenericSelector(NAMESPACE, 'data', getKey);
export const selectError = createGenericSelector(NAMESPACE, 'error', getKey);
export const selectLoading = createGenericSelector(
  NAMESPACE,
  'fetching',
  getKey,
);

export const selectActive = state => selectRound(state, { id: 'active' });
export const selectLive = state => selectRound(state, { id: 'live' });
