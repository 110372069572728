import isEqual from 'lodash/isEqual';

import {
  FETCH_FEED_FAILURE,
  FETCH_FEED_STARTED,
  FETCH_FEED_SUCCESS,
  SHOW_NEW_RESULTS,
} from './constants';

const initialState = {
  featured: undefined,
  nextFeatured: undefined,
  responseList: [],
  cursor: undefined,
  from: undefined, // Oldest timestamp
  to: undefined, // Newest timestamp
  errorLatest: undefined,
  errorOlder: undefined,
  loadingLatest: false,
  loadingOlder: false,
  numOlder: 0,
  hasNew: false,
};

export default function reducer(state = initialState, action) {
  if (action.type === FETCH_FEED_STARTED) {
    return {
      ...state,
      loadingLatest: !action.isOlder ? true : state.loadingLatest,
      loadingOlder: action.isOlder ? true : state.loadingOlder,
      errorLatest: !action.isOlder ? undefined : state.errorLatest,
      errorOlder: action.isOlder ? undefined : state.errorOlder,
    };
  }

  if (action.type === FETCH_FEED_SUCCESS) {
    const {
      response: { featured, ...response },
    } = action;

    const isInitial = state.from == null;
    const isNew = response.to > state.to;

    const responseList =
      response.results.length === 0
        ? state.responseList
        : isNew
        ? [response, ...state.responseList]
        : [...state.responseList, response];

    const cursor = isInitial
      ? 0
      : !isNew || response.results.length === 0
      ? state.cursor
      : state.cursor + 1;

    // Add featured flag
    const nextFeatured = featured.map(item => ({ ...item, featured: true }));

    return {
      ...state,
      featured: state.featured == null ? nextFeatured : state.featured,
      nextFeatured: state.featured == null ? undefined : nextFeatured,
      responseList,
      cursor,
      loadingLatest: isInitial || isNew ? false : state.loadingLatest,
      loadingOlder: !isInitial && !isNew ? false : state.loadingOlder,
      from:
        isInitial || response.from < state.from ? response.from : state.from,
      to: isInitial || response.to > state.to ? response.to : state.to,
      numOlder: !isInitial && !isNew ? state.numOlder + 1 : state.numOlder,
      hasNew:
        cursor > 0 ||
        (state.featured != null &&
          nextFeatured != null &&
          !isEqual(nextFeatured, state.featured)),
    };
  }

  if (action.type === FETCH_FEED_FAILURE) {
    // Ignore errors for latest feed after first success
    const error = action.isOlder
      ? action.error
      : state.responseList.length === 0
      ? action.error
      : undefined;

    return {
      ...state,
      loadingLatest: !action.isOlder ? false : state.loadingLatest,
      loadingOlder: action.isOlder ? false : state.loadingOlder,
      errorLatest: !action.isOlder ? error : state.errorLatest,
      errorOlder: action.isOlder ? error : state.errorOlder,
    };
  }

  if (action.type === SHOW_NEW_RESULTS) {
    return {
      ...state,
      cursor: 0,
      featured:
        state.nextFeatured != null ? state.nextFeatured : state.featured,
      nextFeatured: undefined,
      hasNew: false,
    };
  }

  return state;
}
