import { Fragment } from 'react';
import styled from 'styled-components';

import Round from 'containers/Round';
import { layout } from 'utils/css-mixins';
import media from 'utils/media';

import Header from './Header';
import RoundButton from './RoundButton';

const overlayGradientWidth = 8;
const overlayRightWidth = layout.oneColumn.pageGutter + overlayGradientWidth;
const overlayLeftWidth = layout.oneColumn.pageGutter;

const Root = styled.section`
  position: relative;
  background: white;
  margin-left: ${-1 * layout.oneColumn.pageGutter}px;
  margin-right: ${-1 * layout.oneColumn.pageGutter}px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 1;
  }

  &::before {
    left: 0;
    width: ${overlayLeftWidth}px;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) ${(overlayGradientWidth / overlayLeftWidth) * 100}%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &::after {
    right: 0;
    width: ${overlayRightWidth}px;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1)
        ${(overlayGradientWidth / overlayRightWidth) * 100}%,
      rgba(255, 255, 255, 1) 100%
    );
  }

  ${media.desktop`
    background: initial;
    margin: 0;
    &::before, &::after {
      display: none;
    }
  `}
`;

const RoundWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding: 11px 0 8px ${layout.oneColumn.pageGutter}px;

  &::-webkit-scrollbar {
    display: none;
  }

  &::after {
    content: '';
    width: 1px;
    height: 1px;
    padding-left: ${overlayRightWidth}px;
  }

  ${media.desktop`
    flex-direction: column;
    overflow: visible;
    padding: 0;

    &::after {
      display: none;
    }
  `}
`;

const NoData = styled.div`
  font-size: 11px;
  font-weight: 600;
  line-height: 1.4;
  color: #4a4a4a;
`;

const RoundList = () => (
  <Root>
    <Header
      heading="Aktuella omgångar"
      lead="Vi erbjuder tips, intervjuer och unik statistik till följande omgångar."
    />
    <RoundWrapper>
      <Round
        id="active"
        render={items => (
          <Fragment>
            {items.map(round => (
              <RoundButton key={round.id} round={round} />
            ))}
            {items.length === 0 && (
              <NoData>Just nu finns det inga publicerade tips.</NoData>
            )}
          </Fragment>
        )}
      />
    </RoundWrapper>
  </Root>
);

export default RoundList;
