import { tint } from 'polished';
import propTypes from 'prop-types';
import { Component, createElement } from 'react';
import styled from 'styled-components';

import { colors, zIndex } from 'utils/css-mixins';
import media, { breakpoints } from 'utils/media';

const DropDown = styled.div`
  min-width: 160px;
  display: ${props => (props.open ? 'inline-block' : 'none')};
  position: absolute;
  top: 33px;
  z-index: ${zIndex.dropdown};
  background-color: ${colors.primary};
  color: ${colors.white};
  font-size: 14px;
  box-shadow: none;
  ${props => (props.right ? 'right: 10px;' : 'left: 0;')}
`;

const Wrapper = styled.div`
  display: block;
  position: relative;
`;

const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Li = styled.li`
  white-space: nowrap;
  border-bottom: 1px solid ${tint(0.15, colors.primary)};
  margin-left: 15px;
  margin-right: 15px;

  a {
    color: inherit;
    padding: 8px 15px;
    display: block;
    cursor: pointer;

    &:hover {
      cursor: pointer;
    }
  }

  ${media.max(breakpoints.tablet - 1)`
    border-style: none;
  `}
`;

export default class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  static propTypes = {
    link: propTypes.oneOfType([
      propTypes.string,
      propTypes.func,
      propTypes.object,
    ]).isRequired,
    elem: propTypes.oneOfType([
      propTypes.string,
      propTypes.func,
      propTypes.object,
    ]),
    right: propTypes.bool,
    className: propTypes.string,
  };

  static defaultProps = {
    elem: 'a',
    className: 'dropdown',
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = e => {
    if (this.node && !this.node.contains(e.target)) {
      this.setState({ open: false });
    }
  };

  theLink() {
    const { elem, link, right, ...rest } = this.props;
    const props = {
      ...rest,
      onClick: e => {
        e.preventDefault();
        this.setState({ open: !this.state.open });
      },
    };
    return createElement(elem, props, link);
  }

  render() {
    return (
      <Wrapper ref={node => (this.node = node)}>
        {this.theLink()}
        <DropDown
          className={this.props.className}
          open={this.state.open ? 1 : 0}
          right={this.props.right}
        >
          {this.props.items ? (
            <Ul>
              {this.props.items.map((item, key) => (
                <Li onClick={() => this.setState({ open: false })} key={key}>
                  {item}
                </Li>
              ))}
            </Ul>
          ) : (
            this.props.children
          )}
        </DropDown>
      </Wrapper>
    );
  }
}
