import { AccountCircle } from '@styled-icons/material';
import { Fragment } from 'react';
import Link from 'redux-first-router-link';
import styled from 'styled-components';

import DropDown from 'components/DropDown';
import config from 'config';
import { getLoginUrl, getLogoutUrl } from 'containers/Auth/utils';
import { colors } from 'utils/css-mixins';
import media from 'utils/media';

import { SCROLL_THRESHOLD, TABLET_BREAKPOINT } from './constants';
import { StyledLink } from './NavLinks';

const Signup = styled(StyledLink)`
  background-color: ${colors.buttonprimary};
  padding: 7px 12px;
  border-radius: 3px;
  color: ${colors.white};
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  white-space: nowrap;

  &:active {
    color: ${colors.white};
  }

  ${media.min(TABLET_BREAKPOINT)`
    font-size: 14px;
    font-weight: bold;
    height: 40px;
    padding-right 10px;
    padding-left: 10px;
    background-color: ${colors.buttonprimary};
    color: ${colors.white};
    &:hover {
      border-style: none;
    }
  `}
`;

const Login = styled(Signup)``;

const StyledAccountCircle = styled(AccountCircle)`
  margin-right: 0.5em;
  position: relative;
  top: -1px;
  height: 20px;
  width: 20px;
  ${props =>
    props.scroll > SCROLL_THRESHOLD
      ? `fill: ${colors.white}`
      : `fill: ${colors.primary}`}
  ${media.min(TABLET_BREAKPOINT)`
    fill: ${colors.white}
  `}
`;

const AccountStyle = styled.span`
  ${props =>
    props.scroll > SCROLL_THRESHOLD
      ? `color: ${colors.white};`
      : `color: ${colors.primary};`}
  ${media.min(TABLET_BREAKPOINT)`
    color: ${colors.white};
  `}
`;

const ButtonDiv = styled.div`
  align-items: center;
  display: flex;
  margin-left: auto;

  ${media.max(340)`
    margin-right: ${props => (props.user ? '10px' : 0)};
  `}

  > * + * {
    margin-left: 10px;
  }

  a {
    ${media.max(340)`
      display: ${props => (props.scroll > SCROLL_THRESHOLD ? 'none' : 'block')};
      margin-left: auto;
      margin-right: 0;
    `}
  }
  .dropdown {
    top: 36px;
    a {
      color: ${colors.white};
    }
  }

  ${media.min(TABLET_BREAKPOINT)`
    a {
      ${props => (props.user ? `margin-bottom: 0;` : `margin-bottom: 2px;`)}
    }
  `}
`;

const Tagline = styled.p`
  font-size: 15px;
  line-height: 16px;
  white-space: nowrap;
  color: ${colors.white};

  ${media.min(TABLET_BREAKPOINT)`
    display: none;
  `}

  ${media.desktop`
    display: flex;
  `}

  ${media.max(TABLET_BREAKPOINT - 1)`
    font-size: 14px;
    color: ${props =>
      props.scroll > SCROLL_THRESHOLD ? colors.white : colors.primary};
    display: ${props => (props.scroll > SCROLL_THRESHOLD ? 'none' : 'block')};
  `}

  ${media.max(400)`
    font-size: 12px;
  `}

  ${media.max(360)`
    font-size: 10px;
  `}
`;

const TopDivItems = props => {
  const { open, user, scrollAmount } = props;

  const loggedOutButtons = (
    <ButtonDiv>
      <Signup as={Link} to={{ type: 'PRODUCTS_PAGE' }}>
        Prenumerera
      </Signup>
      <Login href={getLoginUrl()}>Logga in</Login>
    </ButtonDiv>
  );

  const userAccountLink = (
    <StyledLink
      rel="noopener noreferrer"
      target="_blank"
      href={config.USERPAGES_URL}
    >
      Mitt konto
    </StyledLink>
  );

  const logoutLink = <StyledLink href={getLogoutUrl()}>Logga ut</StyledLink>;

  return (
    <Fragment>
      <Tagline scroll={scrollAmount} user={user} open={open}>
        Vi gör dig till en vinnare
      </Tagline>
      {open && user && (
        <Fragment>
          <ButtonDiv scroll={scrollAmount} user={user}>
            {userAccountLink}
            {logoutLink}
          </ButtonDiv>
        </Fragment>
      )}
      {open && !user && loggedOutButtons}

      {!open && user && (
        <ButtonDiv user={user}>
          <DropDown
            link={
              <AccountStyle scroll={scrollAmount}>
                <StyledAccountCircle scroll={scrollAmount} />
                {user.first_name ? user.first_name : 'Konto'}
              </AccountStyle>
            }
            elem={StyledLink}
            items={[userAccountLink, logoutLink]}
            right
          />
        </ButtonDiv>
      )}

      {!open && !user && loggedOutButtons}
    </Fragment>
  );
};

export default TopDivItems;
