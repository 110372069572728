import { useEffect } from 'react';

export default function useBlockScroll({ maxWindow = 10000, enabled = true }) {
  useEffect(() => {
    const onScroll = e => {
      e.preventDefault();
      return false;
    };

    if (window.innerWidth <= maxWindow && enabled) {
      window.addEventListener('touchmove', onScroll, { passive: false });
    }
    return () => {
      window.removeEventListener('touchmove', onScroll, {
        passive: false,
      });
    };
  }, [maxWindow, enabled]);
}
